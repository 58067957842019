<template>
  <div class="col">
    <el-card v-loading="$store.getters['questionnaire/usersLoad']">
      <template #header>
        <div class="wrapping_questions_container">
          <p class="m-0">
            <i class="fa fa-question-circle"></i>
            {{ quest.title }}
          </p>
          <l-button
            type="info"
            class="ml-2 add_version_btn"
            @click="
              open_question_dialog(1, {
                type: quest.type,
                pattern: quest.pattern
              })
            "
          >
            <el-icon class="el-icon-plus"></el-icon>
            {{ localization("Add Version") }}
          </l-button>
        </div>
        <div class="hidden-md-only hidden-lg-only hidden-xl-only">
          <Info :items="questForInfo" />
        </div>
      </template>

      <!-- //? start tree  -->
      <el-tree :data="quest.children" default-expand-all>
        <div
          class="custom-tree-node the_main_wrapping_of_question w-100"
          slot-scope="{ node, data }"
        >
          <div class="mb-3">
            {{ node.level == 1 ? getAgeGroupName(data.title) : data.title }}
            <!-- <i class="fa fa-question"></i> -->
          </div>

          <div class="main_wrapping_for_question">
            <div v-if="node.level == 3" class="content_name_wrapper">
              <l-button
                outline
                round
                size="sm"
                :type="data.removed == 0 ? 'success' : 'danger'"
                class="active_button"
              >
                {{
                  data.removed == 0
                    ? `${localization("Active")}`
                    : `${localization("Inactive")}`
                }}
              </l-button>
              <a
                v-tooltip.top-center="`${localization('Edit question')}`"
                @click="open_question_dialog(2, data)"
                class="edit_strategy"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-tooltip.top-center="
                  data.removed == 0
                    ? `${localization('Delete question')}`
                    : `${localization('Active question')}`
                "
                @click="remove(data.id, data.removed)"
                class="edit_strategy"
              >
                <i v-if="data.removed == 0" class="fa fa-close "></i>
                <i v-else class="fa fa-check text-success"></i>
              </a>
            </div>
          </div>
        </div>
      </el-tree>
    </el-card>
    <!-- //? end tree  -->

    <!-- //? start dialog  -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Question')}`
          : `${localization('Edit Question')}`
      "
      width="60%"
      :visible.sync="questionDialog"
      top="4vh"
      class="add_question_dialog_wrapper"
    >
      <el-form
        :model="question"
        :rules="rules"
        ref="ruleQuestion"
        @submit.prevent="save"
      >
        <el-form-item :label="`${localization('Question')}`" prop="title">
          <el-input v-model="question.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="`${localization('Age')}`" prop="age_group_id">
          <el-select
            v-model="question.age_group_id"
            :placeholder="`${localization('Please select Age group')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in ageList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${localization('Language')}`" prop="language">
          <el-select
            v-model="question.language"
            :placeholder="`${localization('Please select language')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in languages"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="questionDialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="text-center text-capitalize save_button "
          @click="save"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- //? end dialog  -->
  </div>
</template>

<script>
import Info from "../../components/Cards/Info";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  components: {
    Info
  },
  data: () => ({
    languages: [],
    questionDialog: false,
    question: {},
    rules: {
      title: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],

      age_group_id: [
        {
          required: true,
          message: "Please select age group",
          trigger: "change"
        }
      ],
      language: [
        {
          required: true,
          message: "Please select language",
          trigger: "change"
        }
      ]
    },

    ageList: [
      { value: 1, label: "6-12 years old" },
      { value: 2, label: "13 -21 years old" },
      { value: 3, label: "22 or older" }
    ],
    types: [
      { value: 0, label: "Options" },
      { value: 1, label: "Text" }
    ],
    type: null,
    patterns: [
      { label: "Confluence" },
      { label: "Precision" },
      { label: "Sequence" },
      { label: "Technical Reasoning" }
    ]
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    quest() {
      return this.$store.getters["questionnaire/oneQuestion"];
    },
    questForInfo() {
      return Object.entries(this.quest)
        .map(([key, value]) => {
          return { key, value };
        })
        .filter(
          item =>
            item.key !== "parent_id" &&
            item.key !== "id" &&
            item.key !== "old_id" &&
            item.key !== "removed" &&
            item.key !== "isEnabled" &&
            item.key != "children"
        );
    }
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.$store
        .dispatch("questionnaire/show_question", { id: this.id })
        .then(_ => {
          this.languages = this.$store.getters["questionnaire/languages"];
        });
    },
    save() {
      this.$refs["ruleQuestion"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("questionnaire/AddQuestion", { query: this.question })
              .then(_ => {
                this.Refresh({});
                this.questionDialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Question has been added.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("questionnaire/UpdateQuestion", {
                id: this.question.id,
                query: this.question
              })
              .then(_ => {
                this.Refresh({});
                this.questionDialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Question has been updated.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        } else {
          return false;
        }
      });
    },
    remove(id, removed) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `Are you sure you want to delete this version?`
            : "Are you sure you want to Active this version?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("questionnaire/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `` : "",
              text:
                removed == 0
                  ? "Version has been deleted."
                  : "Version has been actived.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    },
    open_question_dialog(type, item) {
      delete item.language_name;
      delete item.language_id;
      this.question = { ...item };
      if (type == 1) {
        this.question.parent_id = parseInt(this.id);
      }
      this.questionDialog = true;
      this.type = type;
    },
    getAgeGroupName(key) {
      switch (key) {
        case 1:
          return "6-12 years";
        case 2:
          return "13 -21 years";
        case 3:
          return "22 or older";
        default:
          return "ABE";
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-tree-node__content {
  height: auto !important;
  padding: 0.5rem 0 !important;
}

::v-deep .el-tree-node__children {
  padding: 0.2rem 0 0.2rem 25px !important;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0.2rem 0 0.2rem 10px !important;
  }
}
::v-deep .el-tree-node {
  white-space: unset !important;
  outline: 0;
}

.the_main_wrapping_of_question {
  display: flex;
  justify-content: space-between;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
}

.main_wrapping_for_question {
  display: flex;
  justify-content: space-between;
  // @media (min-width: 320px) and (max-width: 767px) {
  //   display: block;
  // }
  .content_name_wrapper {
    min-width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
    @media (min-width: 320px) and (max-width: 767px) {
      justify-content: unset;
      gap: 25px;
      margin-top: 15px;
    }
    .active_button {
      display: block;
    }
    .edit_strategy {
      display: block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      padding: 1px;
      border-radius: 50%;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
      transition: 0.3s all;
      color: #777;
      &:hover {
        background-color: #23ccef;
        color: #fff;
      }
    }
  }
}

.wrapping_questions_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 320px) and (max-width: 991px) {
    margin-bottom: 1rem;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
    .add_version_btn {
      margin-top: 1rem;
    }
  }
}

// ? start table ceil
::v-deep .el-table .cell {
  word-break: break-word;
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.add_question_dialog_wrapper {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
</style>
